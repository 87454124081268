import { styled } from "utils/emotion";

import { colors } from "components/Layout/variables";
import media from "utils/mediaqueries";

import { toPx } from "utils/strings";
import config from "~/site.config.js";

const wrapSizes = config?.responsive?.wrapSizes;

export const SliderStyled = styled("div")({
  position: "relative",
  width: "100%",
});

export const ScrollControls = styled("div")({
  position: "relative",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  textAlign: "right",
  paddingLeft: 50,
  paddingRight: 50,

  [media(0, "mobile")]: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  "a + a": {
    marginLeft: "1rem",
  },

  a: {
    width: "auto",
    marginBottom: 0,
  },
});

export const ScrollContainer = styled("div")(
  {
    display: "flex",
    overflowY: "hidden",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    WebkitOverflowScrolling: "touch",
    width: "100%",
    paddingBottom: 20,
    paddingTop: 20,

    "& > div:last-child": {
      paddingRight: 0,
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: colors.greenDarkest,
    },

    "&::-webkit-scrollbar": {
      height: 2,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.green,
      borderRadius: 2,
    },
  },
  (props) =>
    props.firstChildIsText && {
      "> *": {
        "&:first-of-type": {
          [media(0, "mobile")]: {
            maxWidth: "calc(100% - 60px)",
            marginRight: "0",
          },
        },
      },
    },
  (props) =>
    props.noPadding && {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: "0 !important",
    }
);

export const ScrollChild = styled("div")(
  {
    flex: "none",
    scrollSnapAlign: "center",
    scrollSnapStop: "always",
    marginRight: 20,
    flexGrow: 1,
    width: "auto",
    zIndex: 2,

    [media(0, "mobile")]: {
      maxWidth: "100%",
    },
  },
  (props) =>
    props.gutter !== undefined && {
      marginRight: props.gutter,
    },
  (props) =>
    props.maxScrollChildWidth && {
      maxWidth: props.maxScrollChildWidth,
    }
);
