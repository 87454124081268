import React, { useState, useRef, useEffect, useCallback } from 'react'
import _throttle from 'lodash.throttle'
import Button from 'components/Button'

import {
  SliderStyled,
  ScrollControls,
  ScrollContainer,
  ScrollChild,
} from './Slider.styles'

export default function Slider({
  children,
  firstChildIsText,
  gutter,
  noPadding,
  maxScrollChildWidth,
  scrollContainerStyle,
  className,
}) {
  const childrenArray = React.Children.toArray(children)
  const filteredItems = childrenArray ? childrenArray.filter(Boolean) : []

  // Scroll information props.
  const scrollContainerRef = useRef()
  const [canScrollMore, setCanScrollMore] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollChildWidth, setScrollChildWidth] = useState(0)
  const [isScrollable, setIsScrollable] = useState(false)

  /**
   * Set all necessary scroll information props.
   */
  const setScrollValues = () => {
    if (scrollContainerRef.current) {
      const scrollPos = scrollContainerRef.current.scrollLeft
      const canScrollMore = Boolean(
        scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.offsetWidth >
          scrollPos
      )

      setScrollChildWidth(scrollContainerRef.current.children?.[0]?.scrollWidth)
      setScrollPosition(scrollPos)
      setCanScrollMore(canScrollMore)
    }
  }

  /**
   * Effect:
   * Set scroll values if scroll in container occurs.
   */
  useEffect(() => {
    const handleScroll = _throttle(() => setScrollValues(), 200)

    // @jan: is this okay here, can this be done more gracefully (haha, generally)? ~RS
    if (scrollChildWidth <= 0) {
      setScrollValues()
    }

    const isScrollable =
      scrollContainerRef.current.scrollWidth >
      scrollContainerRef.current.offsetWidth
    setIsScrollable(isScrollable)

    scrollContainerRef.current.addEventListener('scroll', handleScroll)

    return () => {
      handleScroll.cancel()
      if (scrollContainerRef.current)
        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  // Callback: scroll to left
  const scrollLeft = useCallback(
    (e) => {
      e && e.preventDefault()
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          left: scrollPosition - scrollChildWidth,
          behavior: 'smooth',
        })
      }
    },
    [scrollPosition, scrollChildWidth]
  )

  // Callback: scroll to right
  const scrollRight = useCallback(
    (e) => {
      e && e.preventDefault()
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          left: scrollPosition + scrollChildWidth,
          behavior: 'smooth',
        })
      }
    },
    [scrollPosition, scrollChildWidth]
  )

  return (
    <SliderStyled className={className}>
      <ScrollContainer
        ref={scrollContainerRef}
        className="dragscroll"
        firstChildIsText={firstChildIsText}
        noPadding={noPadding}
        style={{ ...scrollContainerStyle }}>
        {filteredItems.map((item, index) => {
          if (!item) return null
          return (
            <ScrollChild
              key={'scroll-child' + index}
              className="scroll-child"
              gutter={gutter}
              maxScrollChildWidth={maxScrollChildWidth}>
              {item}
            </ScrollChild>
          )
        })}
      </ScrollContainer>
      <ScrollControls style={{ display: !isScrollable && 'none' }}>
        <Button
          icon="arrowLeft"
          disabled={scrollPosition <= 0}
          onClick={(e) => {
            scrollLeft(e)
          }}
          iconLeft
          size="tiny"
          isNotLink
        />
        <Button
          icon="arrowRight"
          disabled={canScrollMore === false}
          onClick={(e) => {
            scrollRight(e)
          }}
          size="tiny"
          isNotLink
        />
      </ScrollControls>
    </SliderStyled>
  )
}
