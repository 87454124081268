import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Headline from "components/Headline";
import TextVisual from "components/TextVisual";

export const ContentWrapperStyled = styled("div")({
  position: "relative",
  width: "100%",
  textAlign: "center",
  pointerEvents: "all",
  zIndex: 2
});

export const HeadlineStyled = styled(Headline)({
  zIndex: 2,
  marginBottom: 20
});

export const TestimonialsWrapperStyled = styled("div")(({ theme }) => ({
  padding: "25px 0 20px",
  background: theme.sectionTextBackground,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  overflow: "visible",
  maxWidth: "100vw",

  [media(0, "mobile")]: {
    // padding: '25px 0 20px',
  }
}));

export const TestimonialsContainerStyled = styled("div")(({ overlay }) => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
  marginTop: overlay && -100,
  pointerEvents: "none",

  [media(0, "mobile")]: {
    marginTop: overlay && -80
  }
}));

export const TextStyled = styled("div")({
  zIndex: 2
});

export const TextWrapperStyled = styled("div")(({ overlay }) => ({
  zIndex: 2,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: overlay ? "calc(100% - 100px)" : "100%",
  marginTop: overlay && 100,
  textAlign: "left",
  padding: "25px 50px 25px 25px",

  [media(0, "mobile")]: {
    paddingRight: 25,
    marginTop: overlay && 80
  }
}));

export const TextVisualStyled = styled(TextVisual)({
  top: 220,
  width: 0,
  fontSize: 0,
  zIndex: 1,
  opacity: 0.3,
  [media("tabletFluid")]: {
    top: 150,
    left: 100,
    width: "1.2em",
    fontSize: "5vw"
  },
  [media("laptop")]: {
    fontSize: "5vw"
  }
});
