import React from 'react';
import Redactor from 'components/Redactor';

import {
  TestimonialStyled,
  TextStyled,
  ContentWrapper,
  ContentMain,
  Headline,
  AuthorStyled,
  ImageStyled,
  TestimonialWrapperStyled
} from './Testimonial.styles';

export default function Testimonial({
  title,
  testimonialQuote,
  testimonialImage,
  testimonialTextvisual,
}) {

  const textContent = testimonialQuote?.content;

  return (
    <TestimonialWrapperStyled>
      <TestimonialStyled>
        <ContentWrapper>
          <ContentMain>
            {testimonialImage[0] && (
              <ImageStyled
                fluid={testimonialImage[0]}
                aspectRatio={ 1 / 1 }
                style={{width: 600}}
              />
            )}

            {testimonialTextvisual && <Headline element="h3">{testimonialTextvisual}</Headline>}

            <TextStyled margin="0 0 50px 0">
              {textContent ? (
                <Redactor content={textContent} parseType="content" />
              ) : null}
            </TextStyled>

            {title &&
              <AuthorStyled>– {title}</AuthorStyled>
            }
          </ContentMain>
        </ContentWrapper>
      </TestimonialStyled>
    </TestimonialWrapperStyled>
  );
}
