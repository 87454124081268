import { styled } from "utils/emotion";
import { colors } from "components/Layout/variables";
import Text from "components/Text";
import BaseHeadline from "components/Headline";
import Image from "components/Image";
import media from "utils/mediaqueries";

export const TestimonialWrapperStyled = styled("div")(({ theme }) => ({
  [media(0, "mobile")]: {
    width: "100%",
    paddingRight: 10,
    paddingLeft: 10,
  },
}));

export const TestimonialStyled = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  backgroundColor: colors.greyLight,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  color: "black",
  borderRadius: 5,
  boxShadow: "5px 5px 20px rgba(0,0,0,0.2)",
  overflow: "hidden",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",

  // Keeping aspect ratio
  "&::before": {
    position: "absolute",
    zIndex: 0,
    display: "block",
    content: '""',
    width: "100%",
    height: "100%",
    paddingTop: `${(10 / 7) * 100}%`,
    background: "white",
    // 'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7) 100%)'
  },

  "& > *": {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
}));

export const ImageStyled = styled(Image)(({ theme }) => ({
  borderRadius: "100%",
  maxWidth: 120,
  margin: "0 auto 20px",
}));

export const Headline = styled(BaseHeadline)({
  color: "inherit",
  fontSize: 18,
  lineHeight: "20px",
});

export const ContentWrapper = styled("div")({
  zIndex: 1,
  position: "relative",
  padding: "30px 25px",
  height: "100%",
});

export const ContentMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const TextStyled = styled("div")({
  color: "inherit",
  marginBottom: "0",

  "*": {
    margin: "15px 0px",
    color: "inherit",
    fontSize: 15,
    lineHeight: "25px",
  },
});

export const AuthorStyled = styled("span")({
  opacity: 0.5,
  fontStyle: "italic",
});
