import React from "react";
import { graphql } from "gatsby";
import SwitchTheme from "components/SwitchTheme";
import Redactor from "components/Redactor";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import { parseAsText } from "utils/parser";
import Slider from "components/Slider";
import Testimonial from "components/Testimonial";

import {
  ContentWrapperStyled,
  TestimonialsContainerStyled,
  TestimonialsWrapperStyled,
  TextStyled,
  HeadlineStyled,
  TextWrapperStyled,
  TextVisualStyled
} from "./TestimonialsStyled";

const Testimonials = ({ pageBuilder }) => {
  const {
    testimonialsHeadline,
    testimonialsTextvisual,
    testimonialsText,
    testimonialsTheme,
    testimonialsInterlockWithPrev,
    testimonials
  } = pageBuilder;

  const textContent = testimonialsText?.content;
  const overlay = testimonialsInterlockWithPrev;

  return (
    <SwitchTheme name={testimonialsTheme}>
      <TestimonialsWrapperStyled>
        <TestimonialsContainerStyled overlay={overlay}>
          <Grid width="100%">
            <GridItem mobile={1} tabletFluid={1}>
              <ContentWrapperStyled>
                <Slider firstChildIsText maxScrollChildWidth={330}>
                  <TextWrapperStyled overlay={overlay}>
                    <TextVisualStyled>
                      {parseAsText(testimonialsTextvisual)}
                    </TextVisualStyled>
                    <HeadlineStyled>{testimonialsHeadline}</HeadlineStyled>
                    <TextStyled margin="0 0 50px 0">
                      {textContent ? (
                        <Redactor content={textContent} parseType="content" />
                      ) : null}
                    </TextStyled>
                  </TextWrapperStyled>
                  {testimonials.map(item => (
                    <Testimonial key={"testimonial-" + item.id} {...item} />
                  ))}
                </Slider>
              </ContentWrapperStyled>
            </GridItem>
          </Grid>
        </TestimonialsContainerStyled>
      </TestimonialsWrapperStyled>
    </SwitchTheme>
  );
};

export default Testimonials;

export const query = graphql`
  fragment PageBuilderTestimonialsQuery on Craft_PageBuilderTestimonials {
    testimonialsHeadline
    testimonialsTextvisual
    testimonialsTheme
    testimonialsInterlockWithPrev
    testimonialsText {
      content
    }
    testimonials {
      ... on Craft_Testimonial {
        id
        title # testimonial name
        testimonialQuote {
          content
        }
        testimonialImage {
          ...CraftImageFluid
        }
        testimonialTextvisual
      }
    }
  }
`;
